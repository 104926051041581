<template>
  <LeadBoardWidget
    :key="`${orgRef}-lead-board`"
    :orgRef="orgRef"
    :boardRef="boardRef"
    nest-modals
    auto-load
    read-only
  />
</template>

<script>
import { LeadBoardWidget } from '../widgets'

export default {
  name: 'BoardView',
  components: { LeadBoardWidget },
  props: {
    orgRef: String,
    boardRef: {
      type: [String, Number],
      required: true
    }
  }
}
</script>
