import { render, staticRenderFns } from "./LeadBoardWidget.vue?vue&type=template&id=75356152&scoped=true&"
import script from "./LeadBoardWidget.vue?vue&type=script&lang=js&"
export * from "./LeadBoardWidget.vue?vue&type=script&lang=js&"
import style0 from "./LeadBoardWidget.vue?vue&type=style&index=0&id=75356152&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75356152",
  null
  
)

/* custom blocks */
import block0 from "./LeadBoardWidget.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VContainer,VIcon,VList,VListItem,VListItemTitle,VMenu,VTooltip})
