var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"stretch pb-0",attrs:{"fluid":""}},[(_vm.loading)?_c('v-loading'):_c('div',{staticClass:"stretch growing__column"},[_c('AppTitleBar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t(("" + _vm.title))))]},proxy:true}])},[_c('template',{slot:"actions"},[_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canCreate)?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.create}},on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.add)}})],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t(("labels.new" + _vm.capitalizedBoardEntityType))))])]),(_vm.switchViews && _vm.displayType !== 'kanban')?_c('span',[_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.goToKanban}},on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.columnsView)}})],1)]}}],null,false,3343045648)},[_c('span',[_vm._v(_vm._s(_vm.$t('switchView')))])])],1):_vm._e(),(_vm.switchViews && _vm.displayType !== 'table')?_c('span',[_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.goToTable}},on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.tableView)}})],1)]}}],null,false,3181853628)},[_c('span',[_vm._v(_vm._s(_vm.$t('switchView')))])])],1):_vm._e(),(_vm.$board.isAllowed('manage', _vm.board))?_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',{attrs:{"slot":"activator","icon":"","small":""},slot:"activator"},[_c('v-icon',_vm._g({attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.options)}},Object.assign({}, tooltip, menu)))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.actions')))])])]}}],null,false,1979495795)},[_c('v-list',[(_vm.$board.isAllowed('manage', _vm.board))?_c('v-list-item',{on:{"click":_vm.showUsers}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.users')))])],1):_vm._e(),(_vm.$board.isAllowed('destroy', _vm.board))?_c('v-list-item',{on:{"click":_vm.archive}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.archive')))])],1):_vm._e(),(_vm.$board.isAllowed('destroy', _vm.board))?_c('v-list-item',{on:{"click":_vm.destroy}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.delete')))])],1):_vm._e(),(_vm.$board.isAllowed('manage', _vm.board))?_c('v-list-item',{on:{"click":_vm.showRestore}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.restore')))])],1):_vm._e()],1)],1):_vm._e()],1)],2),_vm._t("default",function(){return [_c('router-view')]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }